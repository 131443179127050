import { FC, useState, useEffect } from 'react'
import dayjs from 'dayjs'
import { Box, Typography, Tabs, Tab, useMediaQuery, useTheme } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import Section, { SectionButton, SectionCover } from 'components/Section'
import Dial from 'components/Dial'
import { useBoardsList, useBoardsListPosts } from 'services/boards/boards.hooks'
import InfoIndicator from 'components/InfoIndicator'
import { SignpostOutlined } from '@mui/icons-material'
import { useNotificationsList } from 'services/notifications/notifications.hook'
import WallCalendar from './WallCalendar'
import { useIntl } from 'react-intl'

const WallList: FC = () => {
  const [view, setView] = useState<'board' | 'calendar' | 'messages'>('board')
  const { data = [] } = useBoardsList()
  const { boardsListPosts, isLoading } = useBoardsListPosts(data.map(({ id }) => id))
  const { messagesList } = useNotificationsList()
  const { formatMessage } = useIntl()
  const navigate = useNavigate()
  const theme = useTheme()
  const isDesktop = useMediaQuery(theme.breakpoints.up('md'))

  useEffect(() => {
    if (view === 'board' && isDesktop && boardsListPosts.length > 0) {
      const firstBoardId = boardsListPosts[0].board.id
      const firstPostId = boardsListPosts[0].id
      navigate(`/board/${firstBoardId}/${firstPostId}`)
    } else if (view !== 'board') {
      navigate('/board')
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [view, isDesktop, boardsListPosts])

  return (
    <Box pb='90px'>
      <Tabs
        value={view}
        onChange={(_, value) => setView(value)}
        variant='scrollable'
        scrollButtons='auto'
        sx={{ mb: 3 }}>
        <Tab
          label={formatMessage({
            id: 'boardsMessagesPage.label',
            defaultMessage: 'Wiadomości'
          })}
          value='messages'
        />
        <Tab
          label={formatMessage({
            id: 'wallList.board',
            defaultMessage: 'Tablica'
          })}
          value='board'
        />
        <Tab
          label={formatMessage({
            id: 'wallList.calendar',
            defaultMessage: 'Kalendarz'
          })}
          value='calendar'
        />
      </Tabs>
      {(() => {
        switch (view) {
          case 'calendar':
            return <WallCalendar />
          case 'messages':
            return (
              <>
                <InfoIndicator
                  helperText={formatMessage({
                    id: 'wallList.emptyMessages',
                    defaultMessage: 'Brak wiadomości.'
                  })}
                  Icon={SignpostOutlined}
                  isVisible={!messagesList.length && !isLoading}
                />
                {messagesList.map((message) => (
                  <Section
                    key={`${message.id}`}
                    sx={{
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      px: 0
                    }}>
                    <Typography sx={{ width: '100%', px: 2, fontWeight: 500 }}>
                      {message.subject}
                    </Typography>
                    <Typography variant='body2' sx={{ width: '100%', px: 2 }}>
                      {dayjs(message.createDate).format('DD/MM/YYYY HH:mm')}
                    </Typography>
                    <SectionButton
                      onClick={() => navigate(`/board/messages/${message.id}`)}></SectionButton>
                  </Section>
                ))}
              </>
            )
          default:
            return (
              <>
                <InfoIndicator
                  helperText={formatMessage({
                    id: 'wallList.emptyCalendar',
                    defaultMessage: 'Brak ogłoszeń.'
                  })}
                  Icon={SignpostOutlined}
                  isVisible={!boardsListPosts.length && !isLoading}
                />
                {boardsListPosts.map((post) => (
                  <Section
                    key={`${post.board.id}/${post.id}`}
                    sx={{
                      flexDirection: 'column',
                      alignItems: 'flex-start',
                      px: 0
                    }}>
                    <Box width='100%' px={2} mb={1}>
                      {!!post.multimedia.length && (
                        <SectionCover src={post.multimedia[0].multimedia} alt='' />
                      )}
                    </Box>
                    <Typography sx={{ width: '100%', px: 2, fontWeight: 500 }}>
                      {post.title}
                    </Typography>
                    <Typography variant='body2' sx={{ width: '100%', px: 2 }}>
                      {dayjs(post.eventDate).format('DD/MM/YYYY HH:mm')}
                    </Typography>
                    <SectionButton onClick={() => navigate(`/board/${post.board.id}/${post.id}`)}>
                      {post.createdBy.firstName}
                    </SectionButton>
                  </Section>
                ))}
              </>
            )
        }
      })()}
      <Dial ariaLabel='' onClick={() => navigate('/board/new-event')} />
    </Box>
  )
}

export default WallList
