import { FC } from 'react'
import { Box, Button, useTheme } from '@mui/material'
import { useIntl } from 'react-intl'

const BoardsPostActions: FC = () => {
  const { formatMessage } = useIntl()
  const theme = useTheme()
  return (
    <Box
      sx={{
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        backgroundColor: '#fff',
        p: 2,
        [theme.breakpoints.up('md')]: {
          top: '0',
          bottom: 'unset',
          position: 'static',
          opacity: 0.7,
          p: 2
        }
      }}>
      <Button variant='outlined' fullWidth>
        {formatMessage({
          id: 'boardsPostActions.addCalendar',
          defaultMessage: 'DODAJ DO KALENDARZA'
        })}
      </Button>
    </Box>
  )
}

export default BoardsPostActions
