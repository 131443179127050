import { Preferences } from '@capacitor/preferences';
import dayjs from 'dayjs'
import { useQueryClient, useQuery } from '@tanstack/react-query'
import plAdapter from 'dayjs/locale/pl'
import enAdapter from 'dayjs/locale/en'
import { useMemo, useEffect, useState } from 'react'

const adapters: any = {
  en: enAdapter,
  pl: plAdapter
}

const LANGUAGE_KEY = 'app.language';
const DEFAULT_LANGUAGE = 'pl'
const AVAILABLE_LANGUAGES = [
  {
    label: 'Polski',
    value: DEFAULT_LANGUAGE
  },
  {
    label: 'English',
    value: 'en'
  }
]

export const useLanguage = () => {
  const queryClient = useQueryClient()

  const [lang, setLang] = useState(DEFAULT_LANGUAGE)

  const {
    data: language = DEFAULT_LANGUAGE,
    refetch,
    ...rest
  } = useQuery({
    queryKey: ['language'],
    queryFn: async () => {
      const { value } = await Preferences.get({ key: LANGUAGE_KEY });
      return value || DEFAULT_LANGUAGE;
    },
  })

  const setLanguage = async (newLanguage: string) => {
    await Preferences.set({
      key: LANGUAGE_KEY,
      value: newLanguage,
    });
    
    queryClient.setQueryData(['language'], newLanguage)
    setLang(newLanguage)
  }

  useEffect(() => {
    dayjs.locale(lang)
  }, [lang])

  const dateAdapter = useMemo(() => adapters[lang], [lang])

  return {
    language,
    setLanguage,
    DEFAULT_LANGUAGE,
    AVAILABLE_LANGUAGES,
    dateAdapter,
    ...rest
  }
}
